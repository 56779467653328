import { isEmpty } from "lodash-es";
import type { AbTestsConfig, AbTestVariant } from "~/types/abTest";
import { testConfig, enabledAbTests } from "~/app/ab-tests.config";

export function useAbTest<T extends AbTestsConfig>(config: T) {
  const { $pinia } = useNuxtApp();
  const userStore = useUserStore($pinia);
  const biTrackingStore = useBiTrackingStore($pinia);

  const { internalAbTests } = storeToRefs(biTrackingStore);
  const { userAbTests } = storeToRefs(userStore);
  const { abTests, enabledAbTests } = config;

  type Tests = T["abTests"];
  type TestKeys = Extract<keyof Tests, string>;
  type TestVariants = {
    [K in TestKeys]: Tests[K]["variants"][number]["variant"];
  };

  function handleUserHasAbTestGroup<K extends TestKeys>(name: K, group: TestVariants[K]): boolean {
    if (userAbTests.value[name]) {
      internalAbTests.value[name] = userAbTests.value[name];
    }

    return userHasAbTestGroup(name, group);
  }

  function userHasAbTestGroup(name: TestKeys, group: string): boolean {
    return userAbTests.value[name] === group;
  }

  function userGetAbTestGroup<K extends TestKeys>(name: K): TestVariants[K] {
    return userAbTests.value[name];
  }

  function userSetAbTestGroup<K extends TestKeys>(name: K, group: TestVariants[K]): void {
    userAbTests.value[name] = group;
  }

  function userHasAbTestGroups(): boolean {
    return !isEmpty(userAbTests.value);
  }

  function applyUserAbTestGroups(): void {
    removeDisabledAbTestGroups();
    addMissingAbTestGroups();

    userStore.$persist();
  }

  function removeDisabledAbTestGroups(): void {
    Object.keys(userAbTests.value).forEach((abTestName) => {
      if (!isAbTestEnabled(abTestName)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete userAbTests.value[abTestName];
      }
    });
  }

  function isAbTestEnabled(abTestName: string): boolean {
    return enabledAbTests.includes(abTestName);
  }

  function addMissingAbTestGroups(): void {
    enabledAbTests.forEach((abTestName) => {
      if (!userAbTests.value[abTestName]) {
        setInternalTestGroup(abTestName, getRandomGroup(abTests[abTestName].variants));
      }
    });
  }

  function setInternalTestGroup(name: string, group: AbTestVariant): string {
    userAbTests.value[name] = group.variant;
    return userAbTests.value[name];
  }

  function getRandomGroup(variants: Readonly<AbTestVariant[]>): AbTestVariant {
    const randNbr = Math.floor(Math.random() * 100);
    let currentPercentage = 0;

    for (let i = 0; i < variants.length; i++) {
      const group = variants[i];
      currentPercentage += group.percentage;

      if (randNbr <= currentPercentage) {
        return group;
      }
    }

    return variants[0];
  }

  return {
    handleUserHasAbTestGroup,
    userHasAbTestGroup,
    userHasAbTestGroups,
    userGetAbTestGroup,
    userSetAbTestGroup,
    applyUserAbTestGroups,
    setInternalTestGroup,
    getRandomGroup,
  };
}

export function useDefaultAbTest() {
  return useAbTest({ abTests: testConfig, enabledAbTests });
}
